var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('WithLocation',{attrs:{"id":_vm.locationId},scopedSlots:_vm._u([{key:"default",fn:function({ location, metaFields, isLocationLoading }){return _c('div',{},[(isLocationLoading)?_c('BaseSpinner'):[_c('BaseBreadcrumbs',{attrs:{"breadcrumbs":[
            { link: '/locations', title: 'Locations' },
            { link: '', title: location.JOB_NUMBER__IDENTIFIER },
          ]}}),_c('BaseLocationMeta',{attrs:{"fields":metaFields}})]],2)}}])}),_c('h3',{staticClass:"py-2"},[_vm._v("Daily Reports")]),_c('WithReports',{attrs:{"items-per-page":9,"filters":_vm.filterByLocation},scopedSlots:_vm._u([{key:"default",fn:function({
        reports,
        areReportsLoading,
        searchReports,
        clearReportsSearch,
        totalPages,
        totalRecords,
        page,
        itemsPerPage,
      }){return _c('div',{},[_c('transition',{attrs:{"name":"fade-fast","mode":"out-in","appear":""}},[(areReportsLoading)?_c('BaseSpinner'):(reports && reports.length)?_c('ReportsList',{attrs:{"reports":reports}}):(_vm.$route.query.s && !reports.length)?_c('p',[_vm._v("No reports matched the search \""+_vm._s(_vm.$route.query.s)+"\".")]):_c('p',[_vm._v("No reports found for this location.")])],1),(!areReportsLoading)?_c('BasePagination',{attrs:{"page":page,"total-pages":totalPages,"number-of-records":totalRecords,"items-per-page":itemsPerPage,"record-type":"Reports"},on:{"nextPage":_vm.nextPage,"previousPage":_vm.previousPage}}):_vm._e()],1)}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }